import { state_array as STATES } from "../../variables/Variables";
import { PROCESSING_CODES } from "./payment";

export const FORM_FIELD_KEYS = {
  customerFirstName: "customerFirstName",
  customerMiddleName: "customerMiddleName",
  customerLastName: "customerLastName",
  customerPhone: "customerPhone",
  customerEmail: "customerEmail",
  questionsOrder: "questionsOrder",
  orderId: "orderId",
  message: "message",
  legalName: "legalName",
  tradeName: "tradeName",
  altCompanyName: "altCompanyName",
  dbaname: "dbaname",
  organizedState: "organizedState",
  ownerTitle: "ownerTitle",
  ownerSuffixName: "ownerSuffixName",
  ownerFirstName: "ownerFirstName",
  ownerMiddleName: "ownerMiddleName",
  ownerLastName: "ownerLastName",
  ownerSSN: "ownerSSN",
  address: "address",
  city: "city",
  state: "state",
  country: "country",
  zip: "zip",
  county: "county",
  baseAddressAddress: "baseAddressAddress",
  baseAddressAddress1: "baseAddressAddress1",
  baseAddressAddress2: "baseAddressAddress2",
  baseAddressCity: "baseAddressCity",
  baseAddressState: "baseAddressState",
  baseAddressCountry: "baseAddressCountry",
  baseAddressZip: "baseAddressZip",
  baseAddressCounty: "baseAddressCounty",
  corpAddressAddress1: "corpAddressAddress1",
  corpAddressAddress2: "corpAddressAddress2",
  corpAddressCity: "corpAddressCity",
  corpAddressState: "corpAddressState",
  corpAddressCountry: "corpAddressCountry",
  corpAddressZip: "corpAddressZip",
  corpAddressCounty: "corpAddressCounty",
  representativeAddressAddress1: "representativeAddressAddress1",
  representativeAddressAddress2: "representativeAddressAddress2",
  representativeAddressCity: "representativeAddressCity",
  representativeAddressState: "representativeAddressState",
  representativeAddressCountry: "representativeAddressCountry",
  representativeAddressZip: "representativeAddressZip",
  representativeAddressCounty: "representativeAddressCounty",
  altAddress: "altAddress",
  altAddressAddress1: "altAddressAddress1",
  altAddressAddress2: "altAddressAddress2",
  altAddressCity: "altAddressCity",
  altAddressState: "altAddressState",
  altAddressCountry: "altAddressCountry",
  altAddressZip: "altAddressZip",
  altAddressCounty: "altAddressCounty",
  closingMonth: "closingMonth",
  baseDateYear: "baseDateYear",
  baseDateDay: "baseDateDay",
  baseDateMonth: "baseDateMonth",
  startDateYear: "startDateYear",
  startDateDay: "startDateDay",
  startDateMonth: "startDateMonth",
  dateOfDeathYear: "dateOfDeathYear",
  dateOfDeathDay: "dateOfDeathDay",
  dateOfDeathMonth: "dateOfDeathMonth",
  activityReasonForApplying: "activityReasonForApplying",
  activityPrimaryActivity: "activityPrimaryActivity",
  productDescription: "productDescription",
  activitySpecificProducts: "activitySpecificProducts",
  productExtraQuestion: "productExtraQuestion",
  acceptCreditCardPayments: "acceptCreditCardPayments",
  payEmployeesViaPayroll: "payEmployeesViaPayroll",
  questionsAppliedBefore: "questionsAppliedBefore",
  questionsHire: "questionsHire",
  questionsExcise: "questionsExcise",
  questionsATF: "questionsATF",
  questionsGambling: "questionsGambling",
  questionsHighway: "questionsHighway",
  payLessThan4k: "payLessThan4k",
  annualTaxes: "annualTaxes",
  previousEINNumberFirst2: "previousEINNumberFirst2",
  previousEINNumberLast7: "previousEINNumberLast7",
  numberOfAgricultureEmployees: "numberOfAgricultureEmployees",
  numberOfHouseholdeEmployees: "numberOfHouseholdeEmployees",
  numberOfOtherEmployees: "numberOfOtherEmployees",
  firstDateWagesMonth: "firstDateWagesMonth",
  firstDateWagesDay: "firstDateWagesDay",
  firstDateWagesYear: "firstDateWagesYear",
  firstDateWagesIsValid: "firstDateWagesIsValid",
  primaryPartnerTitle: "primaryPartnerTitle",
  primaryPartnerSuffixName: "primaryPartnerSuffixName",
  primaryPartnerFirstName: "primaryPartnerFirstName",
  primaryPartnerMiddleName: "primaryPartnerMiddleName",
  primaryPartnerLastName: "primaryPartnerLastName",
  primaryPartnerSSN: "primaryPartnerSSN",
  principalOfficerTitle: "principalOfficerTitle",
  principalOfficerSuffixName: "principalOfficerSuffixName",
  principalOfficerFirstName: "principalOfficerFirstName",
  principalOfficerMiddleName: "principalOfficerMiddleName",
  principalOfficerLastName: "principalOfficerLastName",
  principalOfficerSSN: "principalOfficerSSN",
  deceasedSuffixName: "deceasedSuffixName",
  deceasedFirstName: "deceasedFirstName",
  deceasedMiddleName: "deceasedMiddleName",
  deceasedLastName: "deceasedLastName",
  deceasedSSN: "deceasedSSN",
  representativeTitle: "representativeTitle",
  representativeSuffixName: "representativeSuffixName",
  representativeFirstName: "representativeFirstName",
  representativeMiddleName: "representativeMiddleName",
  representativeLastName: "representativeLastName",
  representativeSSN: "representativeSSN",
  responsiblePartyFirstName: "responsiblePartyFirstName",
  responsiblePartyMiddleName: "responsiblePartyMiddleName",
  responsiblePartyLastName: "responsiblePartyLastName",
  responsiblePartySSN: "responsiblePartySSN",
  responsiblePartySuffixName: "responsiblePartySuffixName",
  responsiblePartyTitle: "responsiblePartyTitle",
  nonProfitType: "nonProfitType",
  LLCMembers: "LLCMembers",
  husbandAndWife: "husbandAndWife",
  multiMember: "multiMember",
  taxClassification: "taxClassification",
  trustType: "trustType",
  createrFirstName: "createrFirstName",
  createrMiddleName: "createrMiddleName",
  createrLastName: "createrLastName",
  createrSSN: "createrSSN",
  createrSuffixName: "createrSuffixName",
  trusteeFirstName: "trusteeFirstName",
  trusteeMiddleName: "trusteeMiddleName",
  trusteeLastName: "trusteeLastName",
  trusteeTitle: "trusteeTitle",
  trusteeSSN: "trusteeSSN",
  trusteeAddressAddress1: "trusteeAddressAddress1",
  trusteeAddressAddress2: "trusteeAddressAddress2",
  trusteeAddressCity: "trusteeAddressCity",
  trusteeAddressState: "trusteeAddressState",
  trusteeAddressCountry: "trusteeAddressCountry",
  trusteeAddressZip: "trusteeAddressZip",
  trusteeAddressCounty: "trusteeAddressCounty",
  processingOption: "processingOption",
  cardNumber: "cardNumber",
  cvc: "cvc",
  cardMonth: "cardMonth",
  cardYear: "cardYear",
  firstName: "firstName",
  lastName: "lastName",
  phone: "phone",
  email: "email",
  billingAddress: "billingAddress",
  billingAddressCountry: "billingAddressCountry",
  billingAddressState: "billingAddressState",
  billingAddressAddress1: "billingAddressAddress1",
  billingAddressAddress2: "billingAddressAddress2",
  billingAddressCity: "billingAddressCity",
  billingAddressZip: "billingAddressZip",
  residentAgent: "residentAgent",
  designator: "designator",
  physicalAddressAddress: "physicalAddressAddress",
  physicalAddressCity: "physicalAddressCity",
  physicalAddressState: "physicalAddressState",
  physicalAddressCountry: "physicalAddressCountry",
  physicalAddressZip: "physicalAddressZip",
  physicalAddressCounty: "physicalAddressCounty",
  mailingAddressAddress: "mailingAddressAddress",
  mailingAddressCity: "mailingAddressCity",
  mailingAddressState: "mailingAddressState",
  mailingAddressCountry: "mailingAddressCountry",
  mailingAddressZip: "mailingAddressZip",
  mailingAddressCounty: "mailingAddressCounty",
  trademarkType: "trademarkType",
  markDesc: "markDesc",
  literalElements: "literalElements",
  logoColoredVariety: "logoColoredVariety",
  colorsInLogo: "colorsInLogo",
  logoFile: "logoFile",
  isIncludeLivingPersonName: "isIncludeLivingPersonName",
  isIncludeNicknameName: "isIncludeNicknameName",
  ownName: "ownName",
  isOtherLivingName: "isOtherLivingName",
  includeTranslationWords: "includeTranslationWords",
  translation: "translation",
  firstUseAnywhereYear: "firstUseAnywhereYear",
  firstUseAnywhereDay: "firstUseAnywhereDay",
  firstUseAnywhereMonth: "firstUseAnywhereMonth",
  firstUseInCommerceYear: "firstUseInCommerceYear",
  firstUseInCommerceDay: "firstUseInCommerceDay",
  firstUseInCommerceMonth: "firstUseInCommerceMonth",
  currentTrademark: "currentTrademark",
  individualOrgOrInd: "individualOrgOrInd",
  isNonUsBased: "isNonUsBased",
  orgName: "orgName",
  orgType: "orgType",
  otherOrgType: "otherOrgType",
  orgState: "orgState",
  formationCountry: "formationCountry",
  orgPosition: "orgPosition",
  goods: "goods",
  goodsCategory: "goodsCategory",
  goodsServiceDetail: "goodsServiceDetail",
  description: "description",
  dateOfBirthYear: 'dateOfBirthYear',
  dateOfBirthMonth: 'dateOfBirthMonth',
  dateOfBirthDay: 'dateOfBirthDay',
  customerIdFile: 'customerIdFile',
  driverLicenceOrID: 'driverLicenceOrID',
};

export const SUFFIX_OPTIONS = [
  { title: "DDS", value: "DDS" },
  { title: "MD", value: "MD" },
  { title: "PhD", value: "PhD" },
  { title: "JR", value: "JR" },
  { title: "SR", value: "SR" },
  { title: "I", value: "I" },
  { title: "II", value: "II" },
  { title: "III", value: "III" },
  { title: "IV", value: "IV" },
  { title: "V", value: "V" },
  { title: "VI", value: "VI" },
];

export const TITLE_OPTIONS = [
  { title: "CEO", value: "ceo" },
  { title: "Executor", value: "executor" },
  { title: "Owner", value: "owner" },
  { title: "Managing Member", value: "managing member" },
  { title: "Managing Member/Owner", value: "managing member/owner" },
  { title: "President", value: "president" },
  { title: "Other", value: "other" },
];

export const STATE_OPTIONS = Object.keys(STATES).map(key => {
  return {
    title: STATES[key],
    value: key,
  };
});

export const MONTH_OPTIONS = [
  { value: "1", title: "January" },
  { value: "2", title: "February" },
  { value: "3", title: "March" },
  { value: "4", title: "April" },
  { value: "5", title: "May" },
  { value: "6", title: "June" },
  { value: "7", title: "July" },
  { value: "8", title: "August" },
  { value: "9", title: "September" },
  { value: "10", title: "October" },
  { value: "11", title: "November" },
  { value: "12", title: "December" },
];

export const DAY_OPTIONS = Array.from({ length: 31 }, (_, i) => {
  return { value: `${i + 1}`, title: `${i + 1}` };
});

export const ACTIVITY_REASON_OPTIONS = [
  { value: "started_business", title: "Started New Business" },
  { value: "hired_employees", title: "Hired Employees" },
  { value: "banking_purposes", title: "Banking Purposes" },
  { value: "changed_organization", title: "Changed Type of Organization" },
  { value: "purchased_business", title: "Purchased Business" },
];

export const TRUST_TYPE_OPTIONS = [
  { value: "BankruptcyEstate", title: "Bankruptcy Estate (Individual)" },
  { value: "CharitableLeadAnnuityTrust", title: "Charitable Lead Annuity Trust" },
  { value: "CharitableLeadUnitTrust", title: "Charitable Lead Unitrust" },
  { value: "CharitableRemainderAnnuityTrust", title: "Charitable Remainder Annuity Trust" },
  { value: "CharitableRemainderUnitrust", title: "Charitable Remainder Unitrust" },
  { value: "Conservatorship", title: "Conservatorship" },
  { value: "Custodianship", title: "Custodianship" },
  { value: "Escrow", title: "Escrow" },
  { value: "FNMA", title: "FNMA (Fannie Mae)" },
  { value: "GNMA", title: "GNMA (Ginnie Mae)" },
  { value: "Guardianship", title: "Guardianship" },
  { value: "IrrevocableTrust", title: "Irrevocable Trust" },
  { value: "PooledIncomeFund", title: "Pooled Income Fund" },
  { value: "QualifiedFuneralTrust", title: "Qualified Funeral Trust" },
  { value: "Receivership", title: "Receivership" },
  { value: "RevocableTrust", title: "Revocable Trust" },
  { value: "SettlementFund", title: "Settlement Fund" },
  { value: "Trust", title: "Trust (All Others)" },
];

export const ORG_TYPE_OPTIONS = [
  { value: "LLC", title: "LLC" },
  { value: "C Corporation", title: "C Corporation" },
  { value: "S Corporation", title: "S Corporation" },
  { value: "Nonprofit", title: "Nonprofit" },
  { value: "Partnership", title: "Partnership" },
  { value: "Sole Proprietorship", title: "Sole Proprietorship" },
  { value: "Trust", title: "Trust" },
  { value: "Other", title: "Other" },
];

export const OTHER_ORG_TYPE_OPTIONS = [
  { value: "Agency of the United States Government", title: "Agency of the United States Government" },
  { value: "Incorporated Association", title: "Incorporated Association" },
  { value: "Unincorporated Association", title: "Unincorporated Association" },
  { value: "Body Politic and Corporate", title: "Body Politic and Corporate" },
  { value: "Business Trust", title: "Business Trust" },
  { value: "Charitable Corporation", title: "Charitable Corporation" },
  { value: "Charitable Trust", title: "Charitable Trust" },
  { value: "Charitable, Non-profit Corporation", title: "Charitable, Non-profit Corporation" },
  { value: "Chartered Bank", title: "Chartered Bank" },
  { value: "Congressionally Chartered Nonprofit Organization", title: "Congressionally Chartered Nonprofit Organization" },
  { value: "Cooperative Association", title: "Cooperative Association" },
  { value: "Cooperative Corporation", title: "Cooperative Corporation" },
  { value: "Credit Union", title: "Credit Union" },
  { value: "Federal Agency", title: "Federal Agency" },
  { value: "Federally Chartered Credit Union", title: "Federally Chartered Credit Union" },
  { value: "Federally Chartered Corporation", title: "Federally Chartered Corporation" },
  { value: "Federally-recognized Indian Tribe", title: "Federally-recognized Indian Tribe" },
  { value: "Foundation", title: "Foundation" },
  { value: "Instrumentality", title: "Instrumentality" },
  { value: "Joint Stock Company", title: "Joint Stock Company" },
  { value: "Limited Liability Limited Partnership", title: "Limited Liability Limited Partnership" },
  { value: "Limited Liability Partnership", title: "Limited Liability Partnership" },
  { value: "Municipal Corporation", title: "Municipal Corporation" },
  { value: "National Banking Association", title: "National Banking Association" },
  { value: "Non-profit Association", title: "Non-profit Association" },
  { value: "Non-profit Corporation", title: "Non-profit Corporation" },
  { value: "Non-profit Organization", title: "Non-profit Organization" },
  { value: "Non-stock Corporation", title: "Non-stock Corporation" },
  { value: "Professional Corporation", title: "Professional Corporation" },
  { value: "Public Benefit Corporation", title: "Public Benefit Corporation" },
  { value: "State Agency", title: "State Agency" },
  { value: "State-recognized Indian Tribe", title: "State-recognized Indian Tribe" },
  { value: "State University", title: "State University" },
  { value: "Stock Company", title: "Stock Company" },
  { value: "Tax Exempt Corporation", title: "Tax Exempt Corporation" },
];

export const PROCESSING_OPTIONS_MAP = new Map([
  [
    PROCESSING_CODES.leadDiscount,
    {
      name: "EIN Application Limited Time Discount",
      description: "Your Tax ID/EIN will be delivered in 1-2 business days",
      value: PROCESSING_CODES.leadDiscount,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.leadDiscount20,
    {
      name: "EIN Application Limited Time Discount",
      description: "Your Tax ID/EIN will be delivered in 1-2 business days",
      value: PROCESSING_CODES.leadDiscount20,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.leadDiscount50,
    {
      name: "EIN Application Limited Time Discount",
      description: "Your Tax ID/EIN will be delivered in 1-2 business days",
      value: PROCESSING_CODES.leadDiscount50,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.rush,
    {
      name: "Rush Processing",
      description: "Your Tax ID/EIN will be delivered by the end of the business day",
      value: PROCESSING_CODES.rush,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.standard,
    {
      name: "Standard Processing",
      description: "Your Tax ID/EIN will be delivered in 1-2 business days",
      value: PROCESSING_CODES.standard,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.oneHour,
    {
      name: "One Hour Processing",
      description: "Your Tax ID/EIN will be delivered in 1 hour",
      value: PROCESSING_CODES.oneHour,
      price: 0,
    },
  ],
]);

export const INC_PROCESSING_OPTIONS_MAP = new Map([
  [
    PROCESSING_CODES.leadDiscount,
    {
      name: "Incorporate LLC - Limited Time Discount",
      description: "Your order will be processed in 48-72 hrs",
      value: PROCESSING_CODES.leadDiscount,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.leadDiscount20,
    {
      name: "Incorporate LLC - Limited Time Discount",
      description: "Your order will be processed in 48-72 hrs",
      value: PROCESSING_CODES.leadDiscount20,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.leadDiscount50,
    {
      name: "Incorporate LLC - Limited Time Discount",
      description: "Your order will be processed in 48-72 hrs",
      value: PROCESSING_CODES.leadDiscount50,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.rush,
    {
      name: "Rush Processing",
      description: "Your order will be processed within 24 hrs",
      value: PROCESSING_CODES.rush,
      price: 0,
    },
  ],
  [
    PROCESSING_CODES.standard,
    {
      name: "Standard Processing",
      description: "Your order will be processed in 48-72 hrs",
      value: PROCESSING_CODES.standard,
      price: 0,
    },
  ],
]);
